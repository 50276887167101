<template>
  <div class="test_group_warp">
    <div class="left">
      <LeftController @initList="initList" />
    </div>
    <div class="right">

      <TopCard ref="TopCardRef"
               text="考点训练"
               :showBack="false" />
      <div class="list">
        <div class="warp">
          <el-row v-for="(row,index) in tableData"
                  :key="index">
            <el-col :span="9">
              <div class="subject">
                {{row.check_point_desc}}
              </div>
            </el-col>
            <el-col :span="5">
              <div class="pl">
                易错指数：{{row.error_prone}}
              </div>
            </el-col>
            <el-col :span="4">
              <div class="pl">
                频率:
                <img src="@/assets/icons/icon-星星.png"
                     v-for="(item,index) in row.check_point_star"
                     :key="index" />
              </div>
            </el-col>
            <el-col :span="6">
              <div class="btn"
                   @click="doPaper(row)">
                开始训练
              </div>

              <div class="btn"
                   @click="otherThird(row.children)"
                   v-if="row.if_has_check_point_2">
                三级考点
              </div>
              <div class="btn"
                   @click="other(row.children)"
                   v-if="row.if_has_check_point_2">
                二级考点
              </div>

            </el-col>
          </el-row>
        </div>

      </div>
    </div>
    <Other ref="OtherRef" />
    <OtherThird ref="OtherThirdRef" />
  </div>
</template>

<script>
import LeftController from '@/views/components/left_controller.vue'
import TopCard from '@/views/components/top_card/index.vue'
import { getOneCheckpoint } from '@/api/checkpoint.js'
import Other from './components/other.vue'
import OtherThird from './components/otherThird.vue'

export default {
  data () {
    return {
      tableData: []
    }
  },
  components: {
    LeftController, TopCard, Other, OtherThird
  },
  mounted () {
    this.$removePaperInfo()
  },
  computed: {

  },
  methods: {
    async initList (id) {
      let params = {
        subject_id: id
        // district_id: 1
      }
      const { data } = await getOneCheckpoint(params)
      this.tableData = data.list
    },
    doPaper (row) {
      let params = {
        check_point_id: row.check_point_id,
        selecting_checkpoint: 0,
        type: 6,
      }
      this.$router.push('/doPaper?type=3&form=' + JSON.stringify(params))
    },
    otherThird (row) {

      this.$refs.OtherThirdRef.initList(row)
      this.$refs.OtherThirdRef.dialogVisible = true
    },
    other (row) {

      this.$refs.OtherRef.initList(row)
      this.$refs.OtherRef.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.test_group_warp {
  overflow: hidden;
  display: flex;
  .left {
    width: 100rem;
  }
  .right {
    width: calc(100% - 100rem);
    background: #f6f6f6;
    .tabs {
      margin-top: 22rem;
      display: flex;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 18rem;
      .tab {
        cursor: pointer;
        width: 260rem;
        height: 54rem;
        background: #2196f3;
        border-radius: 30rem;
        color: #ffffff;
        font-size: 24rem;
        font-weight: 800;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .list {
      width: 100%;
      max-height: calc(100vh - 60rem - 40rem);
      -ms-overflow-style: none;
      background: white;
      overflow-y: auto;
      overflow: -moz-scrollbars-none;
      &::-webkit-scrollbar {
        width: 0 !important;
      }
      .el-row {
        height: 80rem;
        border-bottom: 1rem solid #f1f1f1;
        &:hover {
          background: rgba($color: #2196f3, $alpha: 0.1);
        }
      }
      div {
        height: 80rem;
        line-height: 80rem;
      }
      .subject {
        font-size: 20rem;
        font-weight: 500;
        color: #333333;
        text-indent: 50rem;
      }
      .pl {
        font-weight: bold;
        font-size: 20rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
          width: 24rem;
          height: 23rem;
          margin-left: 10rem;
        }
      }
      .btn {
        width: 120rem;
        height: 44rem;
        border-radius: 5rem;
        font-size: 20rem;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        float: right;
        margin-right: 20rem;
        margin-top: 20rem;
        cursor: pointer;
        &:nth-child(1) {
          color: #ffffff;
          background: #2196f3;
        }
        &:nth-child(2) {
          color: #2196f3;
          background: #e6f0ff;
        }
        &:nth-child(3) {
          color: #2196f3;
          background: #e6f0ff;
        }
      }
    }
  }
}
</style>